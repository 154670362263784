import React, { useState } from 'react';
import {
    Form,
    Row,
    Col,
    Button,
    Container,
    Toast,
    ToastContainer
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux';
const validateEmail = (email) => {
    // Regular expression for basic email validation
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
};

function AddressForm(
    {
        submitText,
        submit,
        initialValues0,
        isBilling,
        hideLabels,
    }
) {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        address_update,
        fetching,
        address_data,
        address_errors_data
    } = dataReducer;

    const [showToast, setShowToast] = useState(false);

    const [initialValues, setInitialValues] = useState(initialValues0); // eslint-disable-line no-unused-vars

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [errors, setErrors] = useState(address_errors_data || {});


    React.useEffect(() => {

        if (address_data?.account_id) {
            setFirstName(address_data?.firstName || address_data?.first_name || '');
            setLastName(address_data?.lastName || address_data?.last_name || '');
            setEmail(address_data?.email || '');
            setAddress1(address_data?.address || address_data?.address1 || '');
            setAddress2(address_data?.address2 || '');
            setCity(address_data?.city || '');
            setState(address_data?.state || '');
            setZipcode(address_data?.zip || address_data?.zipcode || '');
        }

    }, [address_update]); // eslint-disable-line react-hooks/exhaustive-deps

    //on change errors_override, setErrors
    /*
    React.useEffect(() => {
        setErrors(errors_override || {});
    }, [errors_override]);
    */
    React.useEffect(() => {
        if (!address_errors_data) return;
        setShowToast(true)
    }, [address_errors_data]);



    //on change initial values, setInitial
    React.useEffect(() => {
        if (fetching) return;
        if (address_data) {
            setFirstName(address_data?.firstName || address_data.first_name || '');
            setLastName(address_data?.lastName || address_data.last_name || '');
            setEmail(address_data?.email || '');
            setAddress1(address_data?.address || address_data?.address1 || '');
            setAddress2(address_data?.address2 || '');
            setCity(address_data?.city || '');
            setState(address_data?.state || '');
            setZipcode(address_data?.zip || address_data?.zipcode || '');
        } else {
            setFirstName(initialValues?.first_name || '');
            setLastName(initialValues?.last_name || '');
            setEmail(initialValues?.email || '');
            setAddress1(initialValues?.address || initialValues?.address1 || '');
            setAddress2(initialValues?.address2 || '');
            setCity(initialValues?.city || '');
            setState(initialValues?.state || '');
            setZipcode(initialValues?.zip || initialValues?.zipcode || '');
        }
    }, [initialValues0]) //eslint-disable-line react-hooks/exhaustive-deps


    //when any state changes, dispatch an action to update the account
    React.useEffect(() => {
        if (!isBilling) {
            return;
        }
        // dipatch all state values
        dispatch({
            type: 'BILLING_ADDRESS_UPDATE',
            data: {
                billing_address: {
                    email,
                    address: address1,
                    address2,
                    city,
                    state,
                    zipcode,
                }
            }
        });
    }, [address1, address2, city, state, zipcode]); // eslint-disable-line react-hooks/exhaustive-deps

    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({
            type: 'ADDRESS_REMEMBER',
            data: null
        });

        dispatch({
            type: 'ADDRESS_ERRORS_REMEMBER',
            data: null
        });
        let _errors = {};


        if (validateEmail(email) === false) {
            _errors.error = true;
            _errors.email = 'Correo Electrónico';
        }

        if ((firstName || "").trim() === '') {
            _errors.error = true;
            _errors.firstName = 'Nombre';
        }


        if ((lastName || "").trim() === '') {
            _errors.error = true;
            _errors.lastName = 'Apellido';
        }


        if ((address1 || "").trim() === '') {
            _errors.error = true;
            _errors.address = 'Dirección';
        }

        if ((city || "").trim() === '') {
            _errors.error = true;
            _errors.city = 'Ciudad';
        }
        if ((state || "").trim() === '') {
            _errors.error = true;
            _errors.state = 'Estado';
        }
        if ((zipcode || "").trim() === '') {
            _errors.error = true;
            _errors.zipcode = 'Código Postal';
        }

        setErrors({ ..._errors });

        if (
            _errors.error

        ) {
            let msg = `Por favor complete los campos requeridos\n`;
            msg += _errors.firstName ? `\n${_errors.firstName}` : '';
            msg += _errors.lastName ? `\n${_errors.lastName}` : '';
            msg += _errors.email ? `\n${_errors.email}` : '';
            msg += _errors.address ? `\n${_errors.address}` : '';
            msg += _errors.city ? `\n${_errors.city}` : '';
            msg += _errors.state ? `\n${_errors.state}` : '';
            msg += _errors.zipcode ? `\n${_errors.zipcode}` : '';
            _errors.msg = msg;


            e.stopPropagation();
            dispatch({
                type: 'ADDRESS_REMEMBER',
                data: {
                    firstName,
                    lastName,
                    email,
                    address1,
                    address2,
                    city,
                    state,
                    zipcode
                }
            });
            dispatch({
                type: 'ADDRESS_ERRORS_REMEMBER',
                data: _errors
            });
            return;
        }

        submit({
            firstName,
            lastName,
            email,
            address1,
            address2,
            city,
            state,
            zipcode
        })

    };

    return (
        <>



            <ToastContainer
                position='middle-center'
                onClick={() => {
                    setShowToast(false);
                }}
            >
                <Toast show={showToast}
                    style={{
                        maxWidth: 350,
                    }}
                    bg='danger'
                    onClose={() => {
                        setShowToast(false);
                    }} delay={18000} autohide
                >
                    <Toast.Body
                        className='text-white text-center'
                    >
                        {(address_errors_data?.msg.split('\n').map(str => <>{str}<br /></>) || 'Por favor complete todos los campos requeridos')}
                    </Toast.Body>
                </Toast>
            </ToastContainer>


            {initialValues?.phone &&
                <div>Número de Celular<br />{initialValues?.phone} &nbsp;</div>
            }

            {
                fetching === 'product_fetching' ?
                    <Container
                        className='d-flex justify-content-center align-items-center p-5'
                    >
                        <span
                            className="spinner-border spinner-border-lg"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </Container>
                    :


                    <div
                    >
                        {!isBilling &&
                            <>
                                <Row className='mt-2'>
                                    <Col  >
                                        <Form.Group controlId="first-name">
                                            {!hideLabels && <Form.Label>Nombre</Form.Label>}
                                            <Form.Control
                                                type="text"
                                                placeholder="Nombre"
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                                required
                                                isInvalid={!!errors.firstName}
                                                disabled={fetching}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col >
                                        <Form.Group controlId="last-name">
                                            {!hideLabels && <Form.Label>Apellido</Form.Label>}
                                            <Form.Control
                                                type="text"
                                                placeholder="Apellido"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                                required
                                                isInvalid={!!errors.lastName}
                                                disabled={fetching}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mb-1'>
                                    <Col md={12}>
                                        <Form.Group controlId="email">
                                            {!hideLabels && <Form.Label>Correo Electrónico</Form.Label>}
                                            <Form.Control
                                                type="email"
                                                placeholder="Correo Electrónico"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                disabled={fetching}
                                                isInvalid={!!errors.email}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Correo no es válido
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row className='mb-1'>
                            <Col md={12}>
                                <Form.Group controlId="address1">
                                    {!hideLabels && <Form.Label>Dirección</Form.Label>}
                                    <Form.Control
                                        type="text"
                                        placeholder="Dirección 1"
                                        value={address1}
                                        onChange={(e) => setAddress1(e.target.value)}
                                        required
                                        isInvalid={!!errors.address}
                                        disabled={fetching}
                                    />
                                </Form.Group>
                            </Col>

                            <Col className='mt-2'>
                                <Form.Group controlId="address2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Dirección 2"
                                        value={address2}
                                        onChange={(e) => setAddress2(e.target.value)}
                                        required
                                        isInvalid={!!errors.address2}
                                        disabled={fetching}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mb-1'>
                            <Col >
                                <Form.Group controlId="city">
                                    {!hideLabels && <Form.Label>Ciudad</Form.Label>}
                                    <Form.Control
                                        type="text"
                                        placeholder="Ciudad"
                                        value={city}
                                        onChange={(e) => setCity(e.target.value)}
                                        required
                                        disabled={fetching}
                                        isInvalid={!!errors.city}
                                    />
                                </Form.Group>
                            </Col>
                            <Col >
                                <Form.Group controlId="state">
                                    {!hideLabels && <Form.Label>Estado</Form.Label>}
                                    <Form.Control
                                        type="text"
                                        placeholder="Estado"
                                        value={state}
                                        onChange={(e) => setState(e.target.value)}
                                        required
                                        isInvalid={!!errors.state}
                                        disabled={fetching}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='mb-1'>

                            <Col  >
                                <Form.Group controlId="zipcode">
                                    {!hideLabels && <Form.Label>Código Postal</Form.Label>}
                                    <Form.Control type="text"
                                        placeholder="33213"
                                        value={zipcode}
                                        onChange={(e) => setZipcode(e.target.value)}
                                        required
                                        isInvalid={!!errors.zipcode}
                                        disabled={fetching}
                                    />

                                </Form.Group>
                            </Col>
                            <Col
                                className='pt-4 d-flex align-items-center '
                            >
                                {
                                    {
                                        "CR": "Costa Rica",
                                        "MX": "México",
                                        "CO": "Colombia"
                                    }[cookies.alani360]
                                }
                                &nbsp;&nbsp;
                                <img
                                    style={{
                                        width: 25,

                                    }}
                                    alt=""
                                    src={`/images/flags/${cookies.alani360}.png`}
                                />
                            </Col>
                        </Row>

                        {!!submitText &&
                            <Button
                                className='mt-3'
                                variant="primary"
                                onClick={(e) => {

                                    // warmp up lambda function
                                    dispatch({
                                        type: 'CART_PAY',
                                        cart_operation_pay: true,
                                        payload: {
                                            cart_id: null,
                                            token: 'WARM_UP_LAMBDA',
                                            method: 'POST',
                                            body: {
                                                payment: {}
                                            }
                                        }
                                    });
                                    // warmp up lambda function

                                    handleSubmit(e);
                                }}
                                disabled={fetching === 'account_saving'}
                            >
                                {fetching === 'account_saving' ?
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>

                                    :
                                    <>{submitText}</>
                                }

                            </Button>
                        }
                    </div >
            }
        </>
    );
}


export default AddressForm;