import { useState, } from "react";
import {
    useNavigate,
    useParams,
} from "react-router-dom";

import {
    Spinner
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
    PayPalScriptProvider,
    PayPalButtons,
    // usePayPalScriptReducer
} from "@paypal/react-paypal-js";

// This value is from the props in the UI
const style = {
    "layout": "vertical",
};


let CLIENT_ID,
    ENV = 'live';

if (ENV === 'sandbox') {
    //console.warn("paypal sandbox")
    CLIENT_ID = "ASSXEHNfEPv8Y0gBz8eF51l9cM-YPq6_4Z01wkc6EvqRbIUfJZCBcPQaifziO0cjzpF0S9a1s3VQw-ur";
} else {
    CLIENT_ID = 'AcuN7X-Wc7THQWTGZnDBLeQ3tShWFl6k_2u9R0t8NI2DDMAJVSxwd91y_Yyue4F-Ld_vUdCxi7SWPMz3';
}

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ showSpinner }) => {

    const navigate = useNavigate();
    let token = useParams().token;
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        cart,
        cart_items,
    } = dataReducer;
    const [paying1, setPaying1] = useState(false);


    function createOrder() {

        setPaying1(true);

        //account.first_name = "CCREJECT-REFUSED";
        //account.first_name = "CCREJECT-SF";

        let body = JSON.stringify({
            cart,
            cart_items,
            account,
            token
        })


        return fetch("https://h4gqwxd22m.execute-api.us-east-1.amazonaws.com/beta/paypal/create-order", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: body,
        })
            .then((response) => response.json())
            .then((order) => {
                setPaying1(false);
                return order.id;
            });
    }
    function onApprove(data) {
        if (data.orderID) {

            setPaying1(true);
            //order was approved so capture payment
            return fetch("https://h4gqwxd22m.execute-api.us-east-1.amazonaws.com/beta/paypal/capture-order", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    orderID: data.orderID,
                    alani_order_id: cart.shopping_cart_id,
                    phone_number: account.phone,
                    token
                }),
            })
                .then((response) => response.json())
                .then((orderData) => {
                    // Your code here after capture the order
                    //console.warn("orderData===> ", orderData)
                    //redirect to success page 


                    setPaying1(false);
                    if (orderData.error) {
                        //console.warn(orderData);
                        alert(orderData.details[0].description);
                    } else {
                        navigate(`/${token}/orders/${cart?.shopping_cart_id}?status=success`);
                    }

                });
        } else {
            console.log(data.name)
            console.log(data.message)
            console.log(data.details)
        }

    }


    return (
        <div
        >

            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[style]}
                fundingSource={"paypal"}
                createOrder={createOrder}
                onApprove={onApprove}
            />
            <PayPalButtons
                style={style}
                disabled={paying1}
                forceReRender={[style]}
                fundingSource={"card"}
                createOrder={createOrder}
                onApprove={onApprove}
            />
            {
                paying1
                &&
                <div className="m-2 d-flex justify-content-center align-items-center">
                    <Spinner
                        animation="border"
                        role="status"
                        style={{ width: 20, height: 20, borderWidth: 2 }}
                    />
                </div>


            }

        </div>
    );
}

export default function App() {
    return (
        <>
            <div style={{ maxWidth: "750px", minWidth: 230 }}>
                <PayPalScriptProvider options={{
                    clientId: CLIENT_ID,
                    components: "buttons",
                    currency: "USD",
                    locale: "es_MX"
                }}>
                    <ButtonWrapper showSpinner={false} />
                </PayPalScriptProvider>
            </div>
        </>
    );
}