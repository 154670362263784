import React from 'react';
import Container from 'react-bootstrap/Container';
import { LinkContainer, } from 'react-router-bootstrap'
import { Link, useNavigate } from "react-router-dom";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { IoLogoWhatsapp, IoChevronBack } from "react-icons/io5";

import { useCookies } from 'react-cookie';
import utils from '@Components/utils';

const Header = () => {
    const navigate = useNavigate();

    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    React.useEffect(() => {
        if (!cookies.alani360 || cookies.alani360 === 'undefined') {
            navigate('/country');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <Navbar bg="light" expand="lg"
            style={{
                position: 'sticky',
                top: 0,
                zIndex: 102,
            }}
        >
            <Container>

                <a
                    style={{ fontSize: '1.5rem', marginRight: '1rem' }}
                    target="_blank" rel="noreferrer" href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}`}

                >
                    <IoChevronBack />
                    <IoLogoWhatsapp />
                </a>
                <Navbar.Brand
                    style={{
                        fontSize: '1.2rem',
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='d-flex flex-row justify-content-center align-items-center me-4'
                        >
                            &nbsp;
                            <img
                                style={{
                                    width: 30,

                                }}
                                alt=""

                                src={`/images/flags/${cookies.alani360}.png`}
                            />
                        </div>
                        <Link to={`/`} >
                            <img
                                style={{
                                    height: 55,
                                }}
                                src="/images/bby_logo.png"
                                alt="Powered by Alani360"
                            />
                        </Link>
                        <div
                            style={{
                                display: 'flex',
                                marginLeft: 10,
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{
                                fontSize: '0.45rem',
                                color: '#808080',
                                marginLeft: 12,
                                marginBottom: 5,
                            }}>powered by</div>
                            <img
                                style={{
                                    width: 65,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    bottom: 15,
                                    position: 'absolute',
                                }}
                                src="/images/alani360_1.png"
                                alt="Powered by Alani360"
                            />
                        </div>
                    </div>



                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <LinkContainer to={`/`} >
                            <Nav.Link  >Inicio</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/bby`} >
                            <Nav.Link  >Best&nbsp;Buy</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/privacy`} >
                            <Nav.Link  >Privacidad</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/brands`} >
                            <Nav.Link  >Marcas</Nav.Link>
                        </LinkContainer>

                        <LinkContainer to={`/consideraciones`} >
                            <Nav.Link  >Consideraciones</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/terms`} >
                            <Nav.Link  >T&C</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/buscar`} >
                            <Nav.Link  >Buscar</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/country`} >
                            <Nav.Link  >País</Nav.Link>
                        </LinkContainer>
                        <Nav.Link
                            target="_blank" rel="noreferrer" href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=Ayuda`} className="div-row"
                        ><IoLogoWhatsapp /> Contacto {utils.getWhatsappNumber(cookies.alani360)}</Nav.Link>

                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    </>


}

export default Header;