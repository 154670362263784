import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { useCookies } from 'react-cookie';

const CoutryButton = ({ setCookie, navigate, country, flag, name }) => {
    return (
        <Button
            variant="transparent"
            style={{ boxShadow: 'none !important' }}
            className="d-flex flex-column justify-content-center align-items-center m-2"
            onClick={() => {
                //set cookie and redirect to home

                setCookie('alani360', country, { path: '/' });
                navigate("/");
            }}
        >
            <img
                style={{
                    width: 40,

                }}
                alt={name}
                src={flag}
            />
            {name}
        </Button>
    );
};


export default function Country() {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['name']); // eslint-disable-line no-unused-vars

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI 360 :: País</title>

                </Helmet>
            </HelmetProvider>

            <div
                className="d-flex 
            flex-column justify-content-center align-items-center p-5"
            >


                <div
                    className="d-flex flex-row  mb-3"
                >
                    <img class="home__section_2__img"
                        src="/images/home/bestbuy-logo-100.jpg"
                        alt="best buy logo"
                        style={{
                            width: 150,

                        }}
                    />

                </div>
                <h4>Bienvenido</h4>

                <h5>Selecciona tu País</h5>


                <div
                    style={{
                        width: '350px',
                    }}
                    className="d-flex 
                         flex-row justify-content-center align-items-center mb-2
                         flex-wrap
                        "

                >

                    {process.env.REACT_APP_COUNTRY_CL === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CL"
                            flag="/images/flags/CL.png"
                            name="Chile"
                        ></CoutryButton>
                    }
                    {process.env.REACT_APP_COUNTRY_CO === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CO"
                            flag="/images/flags/CO.png"
                            name="Colombia"
                        ></CoutryButton>
                    }
                    {process.env.REACT_APP_COUNTRY_CR === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="CR"
                            flag="/images/flags/CR.png"
                            name="Costa&nbsp;Rica"
                        ></CoutryButton>
                    }

                    {process.env.REACT_APP_COUNTRY_DO === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="DO"
                            flag="/images/flags/DO.png"
                            name="República&nbsp;Dominicana"
                        ></CoutryButton>

                    }
                    {process.env.REACT_APP_COUNTRY_MX === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="MX"
                            flag="/images/flags/MX.png"
                            name="México"
                        ></CoutryButton>
                    }
                    {process.env.REACT_APP_COUNTRY_PE === "1" &&
                        <CoutryButton
                            setCookie={setCookie}
                            navigate={navigate}
                            country="PE"
                            flag="/images/flags/PE.png"
                            name="Perú"
                        ></CoutryButton>
                    }

                </div>

            </div >

        </>
    );
}
