import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Button,
    Container,
    Modal,
    Form,
    Toast,
    ToastContainer,
    Spinner
} from 'react-bootstrap';
import Header from '@Components/Header';
import {
    useParams,
    useNavigate
} from "react-router-dom";
import { IoChevronForward, IoSearch, IoCart } from "react-icons/io5";

import FetchingComponent from '@Components/FetchingComponent';
import { useDispatch, useSelector } from 'react-redux';

import utils from '@Components/utils';

function CartItemModal({ show, handleClose, selected_item, token }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1);
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        fetching,
        cart
    } = dataReducer;
    React.useEffect(() => {
        setQuantity(selected_item.quantity);
    }, [selected_item]); // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (fetching === 'cart_updated') {
            if (quantity == 0) { // eslint-disable-line 
                handleClose();
            }

        }
    }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps


    const resetFetching = () => {
        dispatch({
            type: 'FETCHING_SET',
            fetching: null,
        });
    }

    const handleSave = (force_quantity) => {
        let q = quantity;
        if (force_quantity !== undefined) {
            setQuantity(force_quantity);
            q = force_quantity;
        }
        if (
            q === selected_item.quantity
            ||
            q === ''
        ) {
            return;
        }
        dispatch({
            type: 'CART_OPERATION',
            fetching: 'cart_updating',
            fetching_done: 'cart_updated',
            payload: {
                token,
                method: 'PUT',
                body: {
                    sku: selected_item.sku_bby,
                    quantity: force_quantity !== undefined ? force_quantity : q,
                }
            }
        });
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            onShow={() => { resetFetching(); }}
        >
            <Modal.Header closeButton>
                <Button
                    size='sm'
                    variant='dark'
                    className="me-2"
                    onClick={() => {
                        navigate(`/sku/${selected_item.sku_bby}/${token}`);
                    }}
                >
                    <IoSearch />
                </Button>
                <Modal.Title>Cart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {selected_item.data?.name}

                <div
                    className="mt-1 mb-1 m-5 d-flex justify-content-center align-items-center"
                >
                    <img
                        style={{
                            objectFit: 'fill',
                            overflow: 'hidden',
                            maxHeight: 200,
                            margin: 10
                        }}
                        src={selected_item.data?.image}
                        alt={selected_item.name}
                    />
                </div>
                <div className="mt-1 mb-1 d-flex justify-content-between align-items-end">
                    <div
                        className='mb-1'
                    >
                        <Form.Label>Total</Form.Label>
                        <br />
                        <b>{utils.formatCurrency(selected_item.data?.allInPrice, cart?.currency)}</b>
                    </div>

                    <div className="d-flex justify-content-end align-items-end flex-column">
                        <Form.Label>Cantidad</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder=""
                            value={quantity}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setQuantity(e.target.value);
                            }}
                            required
                            style={{
                                width: 70,
                            }}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer
                className='d-flex justify-content-between align-items-center'
            >

                <Button
                    variant="outline-danger"
                    className='border-danger'
                    disabled={fetching === 'cart_updating'}
                    onClick={() => {
                        if (window.confirm("¿Estás seguro que deseas remover este producto del carrito?")) {

                            handleSave(0);
                        }

                    }}
                >
                    Remover
                </Button>

                <Button variant="primary"
                    disabled={fetching === 'cart_updating'}
                    onClick={() => handleSave()}
                >
                    {
                        fetching === 'cart_updating' ?
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            :
                            'Guardar'
                    }
                </Button>
                <ToastContainer className="p-3" style={{ zIndex: 1 }}
                    position='bottom-start'
                    onClick={resetFetching}
                >
                    <Toast show={fetching === 'cart_updated'}
                        style={{
                            width: 200,
                        }}
                        bg='success'
                        onClose={resetFetching}
                        delay={3000}
                        autohide
                    >
                        <Toast.Body
                            className='text-white text-center'
                        >Cantidad Actualizada</Toast.Body>
                    </Toast>
                </ToastContainer>

            </Modal.Footer>
        </Modal >
    );
}

function CartScreen() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let token = useParams().token;
    //const [searchParams, setSearchParams] = useSearchParams();
    //const sku = searchParams.get("sku");
    const [selected_item, setSelectedItem] = React.useState({});
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        cart,
        cart_items,
        fetching,
        api_result
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'CART_OPERATION',
            payload: {
                token,
                method: 'GET',
            },

        })

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI Compras</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <CartItemModal
                token={token}
                show={show}
                handleClose={handleClose}
                selected_item={selected_item}
            />


            <FetchingComponent

                fetching={fetching}
                api_result={api_result}
                account={account}
                whatsappMessage="Mi carrito de compras"
            >

                {
                    !cart ?
                        <Container
                            className='mt-3 pt-4 d-flex justify-content-center align-items-center flex-column'
                            style={{
                                minWidth: 350,
                                maxWidth: 400,
                            }}
                        >

                            <IoCart
                                size={60}
                            />

                            <p>
                                Tu carrito está vacío
                            </p>
                        </Container>
                        :


                        <>
                            <Container
                                className='mt-3'
                                style={{
                                    minWidth: 350,
                                    maxWidth: 400,
                                }}
                            >

                                <b>Carrito de Compras</b>
                                <div>
                                    <b>Total</b> {utils.formatCurrency(cart?.cart_total, cart?.currency)}
                                </div>

                                <hr />
                                {
                                    cart_items?.map((item, index) => {

                                        return <div
                                            key={index}
                                            className='mb-2'
                                        >
                                            <div>
                                                <Button
                                                    variant='dark'
                                                    size='sm'
                                                    onClick={() => {
                                                        setSelectedItem(item);
                                                        setShow(true);

                                                    }}
                                                >
                                                    Cambiar
                                                </Button>
                                                &nbsp;
                                                {item.data.name}
                                            </div>
                                            <div>
                                                {item.quantity} x {utils.formatCurrency(item.data.allInPrice, cart?.currency)}
                                            </div>
                                        </div>

                                    })
                                }


                            </Container>
                            <Container
                                className='justify-content-center align-items-center d-flex'
                            >

                                <Button
                                    size='lg'
                                    onClick={() => {
                                        navigate(`/${account.token}/shipping`);
                                    }}
                                >
                                    Envío Gratis
                                    <IoChevronForward />
                                </Button>
                            </Container>
                        </>
                }
            </FetchingComponent>
        </>
    );
}

export default CartScreen;
