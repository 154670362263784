import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Spinner,
    Card,
    Button
} from 'react-bootstrap';

import { IoLink } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import Header from '@Components/Header';
import moment from 'moment-timezone';
import 'moment/locale/es';
import { useTranslation, } from 'react-i18next';

import utils from '@Components/utils';

import { useCookies } from 'react-cookie';
import NavTabs from '@Components/NavTabs';

import {
    useParams,
    useSearchParams,
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';


const Orders = () => {

    let token = useParams().token;
    const navigate = useNavigate();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        orders,
    } = dataReducer;


    return (
        <>
            {orders?.map((order, index) => <Card
                className='mt-2'
                key={index}

            >
                <Card.Header>
                    <div className='d-flex justify-content-between
                         align-items-center

                    '>

                        <Card.Title>Orden #{order.shopping_cart_id}</Card.Title>
                        <Button
                            onClick={() => navigate(`/${token}/orders/${order.shopping_cart_id}`)}
                        >
                            Detalles
                        </Button>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text>

                        {utils.ProperCase(moment(order?.shopping_cart_paid_dt || order?.shopping_cart_created_dt).format(`MMMM DD 'YY HH:mm`))}

                    </Card.Text>
                    <Card.Text>

                        {utils.formatCurrency(order?.all_in_price_total, order?.currency)}

                    </Card.Text>
                    {order?.ebanx_direct_pay_url &&

                        <a href={order?.ebanx_direct_pay_url} target='_blank' rel="noreferrer">
                            Boleta de Pago <IoLink />
                        </a>

                    }
                    <Card.Text>
                        {order?.order_status}
                    </Card.Text>

                </Card.Body>
            </Card>)}

        </>
    )
}

function OrdersScreen() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let token = useParams().token;
    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const dataReducer = useSelector(state => state.dataReducer);
    const {
        fetching,
        account
    } = dataReducer;


    React.useEffect(() => {
        dispatch({
            type: 'ACCOUNT_FETCH',
            payload: {
                token,
                orders_fetch: 'pending'
            }
        });

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>ALANI</title>
                </Helmet>
            </HelmetProvider>
            <Header />

            <Container
                className='mt-3 mb-5'
                style={{
                    minWidth: 350,
                    maxWidth: 400,
                }}
            >
                {!account?.error &&
                    <NavTabs
                        section='orders'
                    />
                }
                {fetching === 'account_fetching' ?

                    <Container
                        style={{ height: 200 }}
                        className='justify-content-center align-items-center d-flex'
                    >
                        <Spinner animation="border" />
                    </Container >

                    :
                    <>
                        {
                            account.error ?
                                <Container className='mt-5'
                                >
                                    <div style={{
                                        textAlign: 'center',
                                    }}>
                                        {t(account.message)}
                                        <br /><br />
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${utils.urlencode("Mis ordenes")}`}

                                        >
                                            {utils.getWhatsappNumber(cookies.alani360)}
                                        </a>
                                    </div>
                                </Container>
                                :
                                <Orders />
                        }
                    </>
                }
            </Container>
        </>
    );
}

export default OrdersScreen;
