
import {
    Container,
    Spinner
} from 'react-bootstrap';

import { useTranslation, } from 'react-i18next';

import utils from '@Components/utils';
import { useCookies } from 'react-cookie';



const FetchingComponent = (props) => {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const { t } = useTranslation();

    const LinkWhatsapp = ({
        text,
        link
    }) => <a
        target="_blank"
        rel="noreferrer"
        href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${utils.urlencode(link)}`}
    >
            {text}
        </a>

    return <>{props.fetching === 'cart_fetching' ?
        <Container
            className='justify-content-center align-items-center d-flex mt-5'

        >
            <Spinner animation="border" />
        </Container>
        :
        props.api_result?.error ?
            <Container
                className='mt-5'
                style={{
                    minWidth: 300,
                    maxWidth: 400,
                    textAlign: 'center',
                }}
            >
                {t(props.api_result?.message)}
                <div
                    className='mt-3'
                >

                    <LinkWhatsapp
                        text={`Inicia un chat con nosotros`}
                        link={props.whatsappMessage || "Hola"}
                    />
                    <br />
                    en WhatsApp {utils.getWhatsappNumber(cookies.alani360)}

                </div>
            </Container>
            :
            <>
                {props.children}

            </>
    }
    </>
}

export default FetchingComponent;
