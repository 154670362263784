import React from 'react';
import Header from '@Components/Header';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container, Spinner,
    Card,
    Toast,
} from 'react-bootstrap';
import { useParams, Link, useSearchParams } from "react-router-dom";

import utils from '@Components/utils';
import { useDispatch, useSelector } from 'react-redux';


import { IoLinkOutline, IoChevronBack, IoLink } from "react-icons/io5";

import moment from 'moment-timezone';
import 'moment/locale/es';
moment.tz.setDefault("America/Mexico");


const OrderItems = () => {
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        order,
    } = dataReducer;

    if (!order?.order_items) return null;
    return (<>

        {
            order.order_items.map((item, i) => {
                return (
                    <Card key={i}
                        className='mb-3'
                    >
                        <Card.Body>
                            <Card.Text>
                                {item.quantity} x <b>{item.data.name}</b>
                            </Card.Text>
                            <Card.Text
                                className='d-flex justify-content-between align-items-center'
                            >
                                {utils.formatCurrency(item.all_in_price) || ""}

                                <a href={item.data.mobileUrl} target='_blank' rel="noreferrer">
                                    <IoLinkOutline />
                                </a>

                            </Card.Text>
                        </Card.Body>

                    </Card>
                )
            })
        }

    </>)
}

const OrderSummary = () => {
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        order,
    } = dataReducer;

    if (!order?.order) return null;


    return <Container
        className='mb-3 mt-3'
    >
        <div>
            {utils.ProperCase(moment(order.order?.shopping_cart_paid_dt || order?.order?.shopping_cart_created_dt).format(`MMMM DD 'YY HH:mm`))}
        </div>

        <div>
            {order.order.order_status}
            <div>
                {order.order?.ebanx_direct_pay_url &&

                    <a href={order.order?.ebanx_direct_pay_url} target='_blank' rel="noreferrer">
                        Boleta de Pago <IoLink />
                    </a>

                }
            </div>
            {
                order.order?.delivered_date !== null &&
                <div>
                    <b>
                        {utils.ProperCase(moment(order.order?.delivered_date).format(`MMMM DD 'YY HH:mm`))}
                    </b>
                </div>
            }
        </div>

        <div className='mb-3 mt-3'>
            <div>
                {order.order.consignee_name}
            </div>
            <div>
                {order.order.shipping_address}
            </div>
            <div>
                {order.order.shipping_address2}
            </div>
            <div>
                {order.order.shipping_city} {order.order.shipping_state} {order.order.shipping_zip || order.order.shipping_zipcode} {order.order.shipping_country}
            </div>

        </div>
        <div>
            <b>{utils.formatCurrency(order.order?.all_in_price_total)}</b>
        </div>

    </Container>
}

function OrderScreen() {

    const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
    const status = searchParams.get("status");
    const paymentMethod = searchParams.get("paymentMethod");

    let order_id = useParams().order_id;
    let token = useParams().token;

    const [show, setShow] = React.useState(true);
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        order,
        fetching
    } = dataReducer;

    //when leaving page clear product
    React.useEffect(() => {
        return () => {
            dispatch({
                type: 'ORDER_CLEAR'
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    //fetch order
    React.useEffect(() => {
        dispatch({
            type: 'ORDER_FETCH',
            payload: {
                token,
                order_id,
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return <>
        <HelmetProvider>
            <Helmet>
                <title>ALANI Orden {order_id}</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <Container
            className='container-main mt-3'
        >


            <div className='d-flex 
            flex-row 

                align-items-center '>

                <Link

                    to={`/${token}/orders`}
                >
                    <IoChevronBack />
                    Ordenes
                </Link>
                &nbsp;
                <b className='ms-2'>Orden {order_id}</b>
            </div>
            <>
                {status === 'success' &&
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Toast
                            onClose={() => setShow(false)} show={show}
                            delay={10000}
                            autohide
                            bg={"success"}
                            className='mt-3'
                        >
                            <Toast.Body
                                className='text-white text-center'
                            >

                                Gracias, tu orden ha sido confirmada
                                {
                                    paymentMethod === 'oxxo' &&
                                    <div>
                                        <br></br>
                                        En breve recibirás un mensaje con los detalles de tu pago en OXXO
                                        <br />
                                        <img src="/images/pago/oxxo.png" alt="OXXO" style={{ height: 25, marginTop: 10 }} />

                                    </div>
                                }
                                {
                                    paymentMethod === 'spei' &&
                                    <div>
                                        <br></br>
                                        En breve recibirás un mensaje con los detalles de tu pago en SPEI
                                        <br />
                                        <img src="/images/pago/spei.png" alt="SPEI" style={{ height: 25, marginTop: 10 }} />

                                    </div>
                                }

                            </Toast.Body>
                        </Toast>
                    </div>
                }
                {
                    fetching ?
                        <center
                            className='mt-5'
                        >
                            <Spinner animation="border" />
                        </center>
                        :


                        !!order &&
                        <>
                            <OrderSummary />

                            <OrderItems

                            />
                        </>
                }
            </>
        </Container>
    </>
}

export default OrderScreen;
