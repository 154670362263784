import React, { useState } from 'react';
import Header from '@Components/Header';
import { useNavigate } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    Container,
    Card,
    Spinner,
    Button,
    Carousel,
    ToastContainer,
    Toast,
    Form,
    Modal,
} from 'react-bootstrap';
import { useParams, } from "react-router-dom";

import { useCookies } from 'react-cookie';

import api from '@Services/api';
import utils from '@Components/utils';
import { useDispatch, useSelector } from 'react-redux';

import ScrollToTop from "@Components/ScrollToTop";
import '@css/app.css';

import { IoStar, IoStarHalf, IoCart, IoLinkOutline, IoSadOutline, IoCloseCircle, IoChevronForward } from "react-icons/io5";

import moment from 'moment-timezone';
import 'moment/locale/es';
// set colombia time zone and language
moment.tz.setDefault("America/Bogota");

const Rating = ({ rating }) => {
    rating = rating - 1;
    let stars = [];
    for (let i = 0; i < rating; i++) {
        stars.push(<IoStar key={i} color='orange' size={25} />);
    }
    if (rating % 1 !== 0) {
        stars.push(<IoStarHalf key={rating} color='orange' size={25} />);
    }
    return <span>{stars}</span>;
}

const ImageCarousel = ({
    data
}) => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    return (
        <Carousel


            activeIndex={index} onSelect={handleSelect} interval={null}>
            {
                data.filter(i => parseInt(i.width) > 300).map((slide, i) => {
                    return (
                        <Carousel.Item
                            key={i}
                        >
                            <span className='d-flex justify-content-center align-content-center'>
                                <img
                                    className="image_carousel"
                                    src={slide.href}
                                    alt={slide.rel}
                                />
                            </span>
                        </Carousel.Item>
                    )
                })
            }

        </Carousel>
    );
}

const ProductVariations = ({
    product,
    handleClose
}) => {
    let token = useParams().token;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    if (product?.productVariations?.length === 0) {
        return null;
    }
    return (
        <Card.Body>
            <h3>Otras Configuraciones</h3>
            {
                product?.productVariations?.map((variation, i) => {
                    return (
                        <Card.Text key={i} as="div" className='mt-2 mb-4'>
                            <Button
                                variant={product.sku === variation.sku ? 'success' : 'info'}
                                onClick={() => {

                                    handleClose();
                                    if (product.sku === variation.sku) {
                                        return;
                                    }
                                    navigate(`/${token}/sku/${variation.sku}/`);
                                    dispatch({
                                        type: 'PRODUCT_CLEAR'
                                    });
                                }}
                            >
                                {variation.sku}
                            </Button>
                            <div
                                className='d-flex flex-wrap justify-content-center mt-2'
                            >
                                {
                                    variation.variations.map((v, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className='p-1 border rounded m-1 border-secondary variation-btn'

                                            >
                                                {v.value}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </Card.Text>
                    )
                })
            }
        </Card.Body>
    )
}

const ProductCard = ({
    product,
    token,
    onClickVariations,
    onClickOpenBox,
    onClickAlsoViewed,
    onClickRestricted
}) => {



    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        fetching,
        also_viewed,
    } = dataReducer;



    const [showToast, setShowToast] = useState(false);
    const resetToast = () => setShowToast(false);
    const [quantity, setQuantity] = useState(1);

    //if quantity > 10 set to 10
    React.useEffect(() => {
        let max_quantity = product?.availability['max-quantity'];
        if (quantity > max_quantity) {
            setQuantity(max_quantity);
            setShowToast(true);
        }
    }, [quantity]); // eslint-disable-line react-hooks/exhaustive-deps

    //when fetching === cart_updated, navigate to cart ( navigate(`/${account.token}/cart?sku=${product.sku}`);)
    React.useEffect(() => {
        if (fetching === 'cart_updated') {

            navigate(`/${account.token}/cart`);
            dispatch({
                type: 'FETCHING',
                fetching: null
            });

        }
    }, [fetching]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Card
            className='mb-5'
            style={{
                minWidth: 350,
                margin: 'auto',
            }}
        >


            <Card.Body

            >
                <Card.Title>{product.name}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{product.brand}</Card.Subtitle>
                <Rating rating={product.customerReviewAverage} />

            </Card.Body>

            <Card.Body

            >
                {
                    product.images.length === 0 ?

                        <Card.Img style={{ padding: 5, }} variant="top" src={product.image} />
                        :
                        <ImageCarousel
                            data={product.images}
                        />
                }
            </Card.Body>

            <Card.Body>

                <Card.Text
                    as='div'
                >
                    {product.includedItemList.map((item, i) => {
                        return (
                            <div key={i} style={{ fontSize: 12 }}>
                                {item.includedItem}
                            </div>
                        )
                    })
                    }
                </Card.Text>

                <Card.Text
                    className='m-0'
                >
                    {
                        (product.onSale)
                        &&
                        <>
                            <s>{utils.formatCurrency(product.regularPrice, product.currency)}</s>
                            <br />
                        </>
                    }

                    Precio Todo Incluido: <b>{utils.formatCurrency(product.allInPrice, product.currency)}</b>
                    <br />
                    <i style={{ fontSize: 12 }}>(incluye todos los impuestos y envío)</i>
                </Card.Text>


                {account.invalid_token ?
                    <Card.Text>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/${utils.getWhatsappNumber(cookies.alani360)}?text=${encodeURIComponent(`Estoy interesado en sku ${product.sku}`)}`}

                        >
                            Contáctanos a través de WhatsApp para Comprar
                        </a>
                    </Card.Text>

                    :
                    <div
                        className='d-flex  
                            flex-column
                        justify-content-center align-items-center'
                    >
                        {
                            (
                                product?.availability['available-for-shipping']
                                &&
                                !product?.exclusion?.excluded
                            )

                                ?
                                <>
                                    <div className="p-3 ">

                                        <Form.Control
                                            type="number"
                                            placeholder=""
                                            value={quantity}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            onChange={(e) => {
                                                setQuantity(e.target.value);
                                            }}
                                            required
                                            style={{
                                                width: 70,
                                            }}
                                        />
                                    </div>


                                    <Button
                                        variant='success'
                                        size='lg'
                                        style={{
                                            width: 225,
                                        }}
                                        disabled={fetching === 'cart_updating' || product?.exclusion.excluded}
                                        onClick={() => {
                                            dispatch({
                                                type: 'CART_OPERATION',
                                                fetching: 'cart_updating',
                                                fetching_done: 'cart_updated',
                                                payload: {
                                                    token,
                                                    method: 'POST',
                                                    body: {
                                                        sku: product.sku,
                                                        quantity,
                                                    }
                                                },

                                            });

                                        }}
                                    >
                                        {
                                            !!fetching ?
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                :
                                                <>Agregar al Carrito <IoCart /></>
                                        }

                                    </Button>

                                    {
                                        cookies.alani360 === 'CO' &&
                                        <p>
                                            Tiempo aproximado de entrega<br />entre 7 y 10 días
                                        </p>
                                    }

                                    <ToastContainer

                                        style={{ zIndex: 1 }}
                                        position='center'
                                        onClick={resetToast}
                                    >
                                        <Toast show={showToast}
                                            style={{
                                                width: 200,
                                            }}
                                            bg='danger'
                                            onClose={resetToast} delay={3000} autohide
                                        >
                                            <Toast.Body
                                                className='text-white text-center'
                                            >

                                                Maximo {product?.availability['max-quantity']} unidades
                                            </Toast.Body>
                                        </Toast>
                                    </ToastContainer>
                                </>
                                :
                                <div
                                    className='text-danger'
                                >
                                    {product?.availability['available-for-delivery']
                                        ?
                                        'No está disponible para envío'
                                        :
                                        <>


                                            <Button
                                                //onClick={() => navigate(`/${token}/restricciones`)}
                                                variant='danger'
                                                onClick={onClickRestricted}

                                            >

                                                No está disponible <IoSadOutline />

                                            </Button>

                                        </>
                                    }

                                </div>
                        }
                    </div>
                }


                <Card.Text
                    className='mt-2 p-2'

                >
                    <div

                        className='d-flex justify-content-between align-items-center flex-column'
                    >



                        {/*
                            open_box?.length > 0 &&
                            <Button
                                style={{ width: 200 }}
                                variant='warning'
                                onClick={onClickOpenBox}
                                className='mb-2'
                            >Open Box</Button>
            */}
                        {product?.productVariations?.length > 0 &&

                            <Button
                                variant='info'
                                onClick={onClickVariations}
                                style={{ width: 200 }}

                                className='mb-2'
                            >{product?.productVariations.length}  Configuraciones
                            </Button>
                        }
                        {
                            also_viewed?.length > 0 &&

                            <Button
                                style={{ width: 200 }}
                                onClick={onClickAlsoViewed}
                            >{also_viewed?.length} Recomendaciones

                            </Button>
                        }

                    </div>

                </Card.Text>



                <Card.Text
                    className='text-muted text-small'
                >SKU {product.sku}</Card.Text>
                <span dangerouslySetInnerHTML={{ __html: product.longDescription }} />

                <div>
                    <a href={product.mobileUrl} target='_blank' rel="noreferrer">
                        <IoLinkOutline />
                    </a>
                </div>

                <Card.Text
                    className='text-muted   mt-2'
                    style={{
                        fontSize: 9
                    }}
                >Shipping Weight {product.shippingWeight}</Card.Text>






            </Card.Body>

        </Card >
    );
};

const Account = ({
    token
}) => {
    const navigate = useNavigate();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
    } = dataReducer;
    return <div
        className='d-flex justify-content-between align-items-center mb-1'
    >
        {account?.first_name} {account?.last_name}

        <Button
            onClick={() => {

                navigate(`/${token}/cart`);
            }}
        >
            <IoCart />
        </Button>

    </div>

}

const AlsoViewed = ({
    handleClose
}) => {
    let sku = useParams().sku;
    let token = useParams().token;
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        also_viewed
    } = dataReducer;
    if (also_viewed?.length === 0) {
        return null;
    }
    return <>
        <h3>Recomendaciones</h3>
        {also_viewed?.map((product, index) => {

            return <Container
                key={index}
                className='d-flex flex-column justify-content-between align-items-center mb-3'
            >
                <div>
                    <Button
                        size='sm'
                        variant={product.sku === sku ? 'success' : 'primary'}
                        onClick={() => {
                            handleClose();


                            navigate(`/${token}/sku/${product.sku}/`);
                            dispatch({
                                type: 'PRODUCT_CLEAR'
                            });
                        }}
                        style={{
                            float: 'left',
                            marginRight: 5,
                            marginBottom: 0,
                        }}
                    >
                        {product.sku}
                    </Button>

                    {product.names.title}
                </div>

            </Container>

        })}
    </>
}

/*
const OpenBox = () => {
    const dataReducer = useSelector(state => state.dataReducer);
    let {
        open_box
    } = dataReducer;
    if (!open_box) open_box = [];

    if (open_box?.length === 0) {
        return null;
    }
    return <>
        <h3>Open Box</h3>
        {open_box[0]?.offers?.map((product, index) => {

            return <Container
                key={index}
                className='d-flex flex-column justify-content-between align-items-center mb-3'
            >
                <div>
                    {product.condition}
                    <br />
                    {utils.formatCurrency(product.allInPrice)}
                </div>

            </Container>

        })}
    </>
}
*/
const Restricted = ({
    handleClose
}) => {
    let token = useParams().token;
    let sku = useParams().sku;
    const navigate = useNavigate();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        product,
    } = dataReducer;

    const [fetching, setFetching] = useState(false);

    const notify_when_available = async ({
        token,
        sku,
        class_id
    }) => {
        setFetching(true)
        let res = await api.account.operation({
            payload: {
                token,
                sku,
                class_id,
                route: `notify_when_available/${class_id}/?sku=${sku}`,
                method: 'POST'
            }
        });
        setFetching(false)
        if (res.success) {
            alert('Gracias!\nTe avisaré cuando el producto esté disponible');
        }
    }


    return <Container
    >

        <center>
            <IoSadOutline />
            {JSON.stringify(product?.exclusion?.message
                ||
                'Out of Stock')}
        </center>
        <div
            className='mt-3 d-flex flex-column justify-content-center align-items-center'
        >
            <Button
                disabled={fetching}
                variant={fetching ? 'dark' : 'success'}
                onClick={async () => {
                    await notify_when_available({
                        token,
                        sku,
                        class_id: product?.exclusion?.excluded
                    });
                }}
            >Avísame cuando esté disponible
                {
                    fetching &&
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        style={{
                            marginLeft: 10,
                        }}
                    />
                }
            </Button>

            <Button
                className='mt-3'
                variant='outline-danger'
                onClick={() => {
                    navigate(`/${token}/consideraciones`);
                    handleClose();
                }}

            >Ver Consideraciones <IoChevronForward /></Button>

        </div>

    </Container>
}

function ProductScreen() {
    const [cookies, setCookie] = useCookies(['alani360']); // eslint-disable-line no-unused-vars

    let sku = useParams().sku;
    let token = useParams().token;
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [modalType, setModalType] = useState('');
    const dispatch = useDispatch();
    const dataReducer = useSelector(state => state.dataReducer);
    const {
        account,
        product,
    } = dataReducer;


    //when leaving page clear product
    React.useEffect(() => {
        return () => {
            dispatch({
                type: 'PRODUCT_CLEAR'
            });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {


        dispatch({
            type: 'PRODUCT_FETCH_BY_SKU_TOKEN',
            token,
            sku,
            country_code: cookies.alani360
        });
        dispatch({
            type: 'CART_OPERATION',
            payload: {
                token,
                method: 'GET',
            },

        })

    }, [sku]); // eslint-disable-line react-hooks/exhaustive-deps

    return <>
        <HelmetProvider>
            <Helmet>
                <title>ALANI {product?.name || ""}</title>
            </Helmet>
        </HelmetProvider>
        <Header />
        <ScrollToTop />

        <Modal
            show={show && !!product.sku}
            onHide={handleClose}
            style={{
                maxHeight: '90vh'
            }}
            closeButton
        >


            <Modal.Body>
                <IoCloseCircle
                    size={30}
                    style={{ position: 'absolute', top: 10, right: 10, zIndex: 10 }}
                    onClick={handleClose}
                />
                <Card
                    className='mt-3'
                    style={{
                        border: 'none',
                    }}
                >
                    <Card.Body>
                        {
                            modalType === 'variations' && <ProductVariations
                                product={product}
                                handleClose={handleClose}
                            />

                        }
                        {/*
                            modalType === 'open_box' && <OpenBox
                                handleClose={handleClose} />

                        */}
                        {
                            modalType === 'also_viewed' && <AlsoViewed
                                handleClose={handleClose} />
                        }{
                            modalType === 'restricted' && <Restricted
                                handleClose={handleClose}
                            />
                        }
                    </Card.Body>
                </Card>


            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>


        {!product?.name && account !== null ?
            <Container
                className='text-center mt-5'
            >
                <Spinner animation="grow" />

                <br></br>
                <img
                    style={{ width: 100, opacity: 0.5 }}
                    className='mt-5'
                    src='https://bby.alani360.com/images/home/bestbuy-logo-100.jpg' alt='best buy logo'
                />

            </Container>
            :
            <Container
                className='text-center mt-3 container-md mobile-max-width'
            >

                <Account
                    token={token}
                />
                <ProductCard
                    token={token}
                    product={product}
                    onClickVariations={() => {
                        setModalType('variations');
                        handleShow();
                    }}
                    /*onClickOpenBox={() => {
                        setModalType('open_box');
                        handleShow();
                    }}*/
                    onClickAlsoViewed={() => {
                        setModalType('also_viewed');
                        handleShow();
                    }}
                    onClickRestricted={() => {
                        setModalType('restricted');
                        handleShow();
                    }}
                />







            </Container>
        }
    </>
}

export default ProductScreen;
